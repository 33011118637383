import React, { ReactNode, useContext, useEffect } from "react";
import PropTypes, { InferProps } from "prop-types";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import analytics from "../../services/analytics";

import { DotsVerticalIcon, QrcodeIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { io } from "socket.io-client";

import { useGetFrames } from "../../hooks/frames/useGetFrames";
import { useCreateFrame } from "../../hooks/frames/useCreateFrame";
import { CONSTANT, limit } from "../../static/constants";
import Loader from "../../components/shared/loader";
import AddFrame from "../../components/modal/AddFrame";
import EditFrame from "../../components/modal/EditFrame";
import PairFrame from "../../components/modal/PairFrame";
import { Frame } from "../../components/model/Frame";
import TableRow from "../../components/tables/table-row";
import DeleteModal from "../../components/modal/DeleteModal";
import TableTitleBar from "../../components/tables/table-title-bar";
import { useAuth } from "../../authn/authn";
import { useDeleteFrame } from "../../hooks/frames/useDeleteFrame";
import { useUpdateFrame } from "../../hooks/frames/useUpdateFrame";
import { usePairFrame } from "../../hooks/frames/usePairFrame";

import ArtworkThumb from "../../components/shared/thumbnails/ArtworkThumb";
import ArtworkCollectionThumb from "../../components/shared/thumbnails/ArtworkCollectionThumb";
import TableBanner from "../../components/shared/TableBanner";
import TableContainer from "../../components/shared/TableContainer";
import MainContainer from "../../components/shared/MainContainer";
import { plaqueDisplay } from "../../static/Enums";
import FrameDropDown from "../../components/shared/FrameDropDown";
import Table from "../../components/shared/Table";
import TableEmptyState from "../../components/tables/table-empty-state";
import ArtOrCollTitle from "../../components/shared/ArtOrCollTitle";
import { useNavigate } from "react-router-dom";
import { generalToastContext } from "../../contexts/general-toast-context-provider";
import useAccountLimitation from "../../hooks/frames/useAccountLimitation";
import UpgradePlanModal from "../../components/modal/UpgradePlanModal";
import { frame } from "web3modal/dist/providers/connectors";

const WS_ENDPOINT =
  process.env.REACT_APP_STRAPI_API_URL || "http://localhost:1337";
function Frames(props: InferProps<typeof Frames.propTypes>) {
  const navigation = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPairModal, setShowPairModal] = useState(false);
  const { selectedPlan } = useContext(generalToastContext);
  const [subInfoDialog, setSubInfoDialog] = useState<boolean>(false);
  const authn = useAuth();
  const [order, setOrder] = useState("name:ASC");
  const [createError, setCreateError] = useState("");
  const [copiedMessage, setCopiedMessage] = useState<any>({});
  const [frameId, setFrameId] = useState<string>();
  const [selectedFrame, setSelectedFrame] = useState<Frame>();
  const [allFrames, setAllFrame] = useState<Frame[]>();

  const deleteFrame = useDeleteFrame();
  const updateFrame = useUpdateFrame();
  const pairFrame = usePairFrame();
  const { loading, error, frames, fetchMore, refetch, framesCount } =
    useGetFrames({
      accountId: authn?.getAccountId(),
      limit: limit,
      offset: 0,
      sort: order,
    });

  const { frameLimit, frameConsumed } = useAccountLimitation(frames?.length);

  const createFrame = useCreateFrame();

  useEffect(() => {
    setAllFrame(frames);
    // localStorage.setItem("frames", JSON.stringify(frames));
  }, [frames]);

  // wire up socket events whenever we add or remove a frame
  // TODO: as we add realtime functionality, this should probably be abstracted
  useEffect(() => {
    // Initiate socket connection
    const socket = io(WS_ENDPOINT, {
      withCredentials: true,
    });

    socket.on("connect", () => {
      console.log("[] connected");
    });

    if (frames?.length > 0) {
      frames.forEach((frame: any) => {
        // on initial connect set the frameData
        socket.on(`${frame.slug}`, (data: any) => {
          refetch();
        });
      });
    }

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frames?.length]);

  const onOpenCreateModal = () => {
    if (frameLimit > frameConsumed) {
      setShowModal(true);
    } else {
      setSubInfoDialog(true);
    }
    setCreateError("");
  };
  const onCloseCreateModal = async (frame: any = null) => {
    if (frame === null) {
      setShowModal(false);
    }
    if (frame) {
      setLoader(true);
      try {
        const result = await createFrame({
          variables: {
            input: {
              data: frame,
            },
          },
        });
        analytics.track("frame: created", {
          frameId: result.data.createFrame.frame.id,
        });
        setShowModal(false);
      } catch (e: any) {
        console.log(e.message);

        setCreateError(e.message);
      } finally {
        setLoader(false);
      }
    }
  };

  // Edit modal handlers
  const onOpenEditModal = (frame: any) => {
    setFrameId(frame.id);
    setSelectedFrame(frame);
    setShowEditModal(true);
  };
  const onCloseEditModal = async (frame: any = null) => {
    setShowEditModal(false);
    if (frame) {
      setLoader(true);
      await updateFrame({
        variables: {
          input: {
            data: frame,
            where: {
              id: frameId + "",
            },
          },
        },
      });
      analytics.track("frame: updated", {
        frameId: frame.id,
        source: "frame edit modal",
      });
      setLoader(false);
    }
  };

  // Pair modal handlers
  const onOpenPairModal = (frame: any) => {
    setFrameId(frame.id);
    setSelectedFrame(frame);
    setShowPairModal(true);
  };
  const onClosePairModal = async (frame: any = null) => {
    setShowPairModal(false);
    if (frame) {
      setLoader(true);
      try {
        await pairFrame({
          variables: {
            input: {
              data: {
                pairingCode: frame.pairingCode,
              },
              where: {
                id: frameId + "",
              },
            },
          },
        });
        analytics.track("frame: paired", {
          frameId: frame.id,
        });
      } catch (ex: any) {
        toast.error(ex?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } finally {
        setLoader(false);
      }
    }
  };

  // Delete modal handlers
  const onOpenDeleteModal = (id: string) => {
    setShowDeleteModal(true);
    setFrameId(id);
  };
  const onCloseDeleteModal = async (state: boolean) => {
    try {
      if (state) {
        setLoader(true);
        const res = await deleteFrame({
          variables: {
            input: {
              where: {
                id: frameId + "",
              },
            },
          },
        });
        analytics.track("frame: deleted", {
          frameId: frameId,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
      setShowDeleteModal(false);
    }
  };

  const onPlaqueSelect = async (selected: any, frameId: string) => {
    setLoader(true);
    const res = await updateFrame({
      variables: {
        input: {
          data: {
            plaque: selected,
          },
          where: {
            id: frameId,
          },
        },
      },
    });
    analytics.track("frame: updated", {
      frameId: frameId,
      source: "frame list dropdown",
    });
    setLoader(false);
  };

  function classNames(...classes: any) {
    return classes.filter(Boolean).join("");
  }

  const fetchMoreData = () => {
    setLoader(true);
    fetchMore({
      variables: {
        offset: allFrames?.length,
        limit: limit,
      },
    }).then((fetchMoreResult: any) => {
      setAllFrame((frames) => [...frames!, ...fetchMoreResult?.data?.frames]);
    });
    setLoader(false);
  };

  const onClickUrl = (url: any) => {
    navigator.clipboard.writeText(url);
    setCopiedMessage({
      [url]: "Copied!",
    });
    setTimeout(() => {
      setCopiedMessage({
        [url]: "",
      });
    }, 1000);
  };

  const openCollectionDetails = (id: number) => {
    if (id) navigation("/collections/" + id);
  };

  const closeSubInfoModal = () => {
    setSubInfoDialog(false);
  };

  return (
    <>
      <div>
        <MainContainer>
          <div className="sm:text-center lg:text-left">
            <TableTitleBar>
              <TableBanner
                title={CONSTANT.FRAMES}
                count={framesCount}
                addHandler={onOpenCreateModal}
                buttonTitle={CONSTANT.NEW_FRAME}
                buttonTitleSmallSize={CONSTANT.CREATE}
              />
            </TableTitleBar>
            {<Loader isLoading={loading} />}
            <TableContainer>
              <InfiniteScroll
                dataLength={Number(allFrames?.length) || 0}
                next={fetchMoreData}
                hasMore={
                  (Number(framesCount) || 0) > (Number(allFrames?.length) || 0)
                }
                loader={<h4></h4>}
              >
                {!loading && (!allFrames || allFrames?.length === 0) ? (
                  <TableEmptyState
                    title="There are no frames yet."
                    subtitle={(
                        <>
                            <p>To pair a display:</p>
                            <span>
                                1. Navigate to 0fra.me using your display's browser. It will give you a pairing code.
                                <br />
                                2. Click 'Create frame' above and enter the pairing code.
                            </span>
                        </>

                    )}
                  />
                ) : (
                  <Table>
                    <thead className="bg-table-header-bg text-table-header-fg text-left text-sm uppercase tracking-wider">
                      <tr>
                        <th scope="col" className="px-6 py-3 font-semibold ">
                          {CONSTANT.NAME}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 font font-semibold"
                        >
                          {CONSTANT.DISPLAYING}
                        </th>
                        <th
                          scope="col"
                          className="px-6 md:pr-20 py-3 font-semibold"
                        >
                          <span className="hidden sm:block">
                            {CONSTANT.CONN_DESKTOP}
                          </span>
                          <span className="sm:hidden">{CONSTANT.CONN}</span>
                        </th>
                        <th scope="col" className="px-6 font font-semibold">
                          {CONSTANT.LINK}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 font font-semibold"
                        >
                          {CONSTANT.PLAQUE}
                        </th>
                        <th
                          scope="col"
                          className="w-8 px-6 py-3 font font-semibold"
                        ></th>
                      </tr>
                    </thead>

                    <tbody className="bg-indigo divide-y divide-table-border-color ">
                      {allFrames?.map((frame, index) => (
                        <TableRow
                          key={frame.id}
                          // isDisabled={index + 1 > frameLimit}
                        >
                          <td className="px-6">
                            <div className="text-sm text-primary font-semibold ">
                              {frame?.name}
                            </div>
                          </td>
                          <td className="px-6 py-2 text-left">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-14 w-14 mr-2">
                                {frame?.artworkCollection ? (
                                  <ArtworkCollectionThumb
                                    src={frame?.artworkCollection?.artworks}
                                  />
                                ) : (
                                  <div className="flex-shrink-0">
                                    <ArtworkThumb artwork={frame.artwork} />
                                    {/* TODO: handle web and art w/o thumb url */}
                                    {/* <ArtworkThumb artwork={data} /> */}
                                  </div>
                                )}
                              </div>
                              <ArtOrCollTitle
                                data={frame}
                                clickHundler={openCollectionDetails}
                              />
                            </div>
                          </td>
                          <td className="px-6 md:pr-20 ">
                            <div className="flex items-center">
                              <span className="flex h-3 w-3 ">
                                <span
                                  className={classNames(
                                    frame?.currentConnectionCount > 0
                                      ? " bg-green-500"
                                      : "bg-gray-300 ",
                                    " relative inline-flex rounded-full h-3 w-3"
                                  )}
                                >
                                  <span
                                    className={classNames(
                                      frame?.isConnected
                                        ? "animate-ping relative inline-flex h-3 w-3 rounded-full bg-green-400 opacity-75"
                                        : ""
                                    )}
                                  ></span>
                                </span>
                              </span>
                              <div>
                                <span className="ml-3 text-sm text-table-cell-color ">
                                  {" "}
                                  {frame.currentConnectionCount > 0 &&
                                    frame.currentConnectionCount}
                                </span>
                              </div>
                              {/* {!frame?.isConnected && (
                                <QrcodeIcon
                                  className="h-5 mr-2 hover:cursor-pointer"
                                  onClick={() => onOpenPairModal(frame)}
                                />
                              )} */}
                            </div>
                          </td>

                          <td className="px-6 relative">
                            <div className="flex align-center">
                              <div
                                className="text-sm text-gray-900 frame__url cursor-pointer"
                                onClick={() => {
                                  onClickUrl(frame?.url);
                                }}
                              >
                                {frame?.url}{" "}
                                {/* <DocumentDuplicateIcon className="h-4 ml-1 text-gray-400 hover:text-primary-500 inline cursor-pointer overflow-x-hidden" /> */}
                              </div>
                              {copiedMessage[frame?.url] ? (
                                <span className="text-gray-500 z-50 absolute top-1/2 right-0 frame__copied-alert">
                                  <span className="bg-white rounded-md p-1">
                                    {copiedMessage[frame?.url]}
                                  </span>
                                </span>
                              ) : null}
                            </div>
                          </td>
                          <td className="px-6">
                            <div className="">
                              <FrameDropDown
                                width="w-32"
                                type="plaque"
                                default={frame.plaque}
                                options={plaqueDisplay}
                                onSelect={(option: number) =>
                                  onPlaqueSelect(option, frame.id)
                                }
                              ></FrameDropDown>
                            </div>
                          </td>
                          <td className="text-right">
                            <Menu as="div" className=" inline-block text-left">
                              <div>
                                <Menu.Button className="justify-center w-full px-4 py-2 bg-white text-sm font-medium  hover:bg-gray-50">
                                  <DotsVerticalIcon
                                    className=" h-5 text-gray-500"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>

                              <div className="absolute right-5 z-50">
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="origin-top-right absolute right-0 rounded-md z-10 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="min-w-max text-right">
                                      <Menu.Item>
                                        <a
                                          onClick={() => onOpenEditModal(frame)}
                                          className="px-3 py-2 block bg-white rounded-md hover:bg-table-header-bg cursor-pointer text-sm w-full "
                                        >
                                          {CONSTANT.EDIT}
                                        </a>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <a
                                          onClick={() => onOpenPairModal(frame)}
                                          className="px-3 py-2 block bg-white rounded-md hover:bg-table-header-bg cursor-pointer text-sm w-full "
                                        >
                                          {CONSTANT.PAIR_FRAME_MENU_ITEM}
                                        </a>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <a
                                          onClick={() =>
                                            onOpenDeleteModal(frame.id)
                                          }
                                          className="px-3 text-red-600 py-2 block bg-white rounded-md hover:bg-table-header-bg cursor-pointer text-sm w-full "
                                        >
                                          {CONSTANT.DELETE}
                                        </a>
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </div>
                            </Menu>
                          </td>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                )}
              </InfiniteScroll>
            </TableContainer>
            <AddFrame
              isOpen={showModal}
              user={authn?.getUser()?.id}
              onCloseModal={onCloseCreateModal}
              error={createError}
            ></AddFrame>
            <EditFrame
              isOpen={showEditModal}
              frame={selectedFrame}
              onCloseModal={onCloseEditModal}
            ></EditFrame>
            <PairFrame
              isOpen={showPairModal}
              frame={selectedFrame}
              onCloseModal={onClosePairModal}
            ></PairFrame>
            <DeleteModal
              title={CONSTANT.FRAME}
              onCloseDeleteModal={(value: boolean) => onCloseDeleteModal(value)}
              isOpen={showDeleteModal}
            ></DeleteModal>
            {/* Dialog component */}
            <UpgradePlanModal
              isModalOpen={subInfoDialog}
              onCloseDialog={closeSubInfoModal}
              selectedPlan={selectedPlan}
              limit={frameLimit}
              type="frame"
              consumed={frameConsumed}
            />
            <Loader isLoading={loader}></Loader>
          </div>
        </MainContainer>
      </div>
    </>
  );
}

Frames.propTypes = {};

export default Frames;
